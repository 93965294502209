<script>
import {required, email} from "vuelidate/lib/validators";
import {
	authMethods,
	notificationMethods,
} from "@/state/helpers";
import LogoSvg from "@/components/Landing/LogoSvg.vue";
import router from "../../../router";

/**
 * Register component
 */
export default {
	components: {
		LogoSvg
	},
	data() {
		return {
			email: "",
			password: "",
			submitted: false,
		};
	},
	validations: {
		email: {required, email},
		password: {required},
	},
	computed: {
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
	},
	methods: {
		...authMethods,
		...notificationMethods,
		// Try to log the user in with the username
		// and password they provided.
		tryToLogIn() {
			this.submitted = true;
			// stop here if form is invalid
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			} else {
				this.logIn({
					email: this.email,
					password: this.password,
				})
			}
		},
		goToMagicLink() {
			router.push('/magic-link')
		}
	},
};
</script>

<template>
	<div class="account-pages my-5 pt-sm-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="text-center mb-4">
						<router-link to="/" class="auth-logo mb-5 d-block">
							<LogoSvg/>
						</router-link>

						<h4>{{ $t('login.title') }}</h4>
					</div>
					<div class="card">
						<div class="card-body p-4">
							<b-alert
								:variant="notification.type"
								class="mt-3"
								v-if="notification.message"
								:show="notificationAutoCloseDuration"
								dismissible
							>{{ notification.message }}
							</b-alert>
							<div class="p-3">
								<form @submit.prevent="tryToLogIn">
									<div class="form-group">
										<label>{{ $t('login.form.email.label') }}</label>
										<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
											<div class="input-group-prepend">
                        <span class="input-group-text border-light text-muted">
                          <i class="ri-mail-line"></i>
                        </span>
											</div>
											<input
												v-model="email"
												type="email"
												class="form-control bg-soft-light border-light"
												:class="{ 'is-invalid': submitted && $v.email.$error }"
												:placeholder="$t('login.form.email.placeholder')"
											/>
											<div v-if="submitted && $v.email.$error" class="invalid-feedback">
                        <span
							v-if="!$v.email.required"
						>{{ $t('login.form.email.validation.required') }}</span>
												<span v-if="!$v.email.email">{{
														$t('login.form.email.validation.invalid')
													}}</span>
											</div>
										</div>
									</div>

									<div class="form-group mb-4">
										<div class="float-right">
											<router-link
												tag="a"
												to="/forgot-password"
												class="text-muted font-size-13"
											>{{ $t('login.form.forgotlink') }}
											</router-link>
										</div>
										<label>{{ $t('login.form.password.label') }}</label>
										<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
											<div class="input-group-prepend">
                        <span class="input-group-text border-light text-muted">
                          <i class="ri-lock-2-line"></i>
                        </span>
											</div>
											<input
												v-model="password"
												type="password"
												class="form-control bg-soft-light border-light"
												:placeholder="$t('login.form.password.placeholder')"
												:class="{ 'is-invalid': submitted && $v.password.$error }"
											/>
											<div
												v-if="submitted && !$v.password.required"
												class="invalid-feedback"
											>{{ $t('login.form.password.validation.required') }}
											</div>
										</div>
									</div>

									<div>
										<button
											class="btn btn-primary btn-block"
											type="submit"
										>{{ $t('login.form.button.text') }}
										</button>
									</div>
								</form>
								<p class="horizontal-border">OR</p>
								<button
									class="btn btn-magic btn-block"
									@click="goToMagicLink"
								>Send Me a Magic Link
								</button>
							</div>
						</div>
					</div>

					<div class="mt-5 text-center">
						<p>
							{{ $t('login.signuptext') }}
							<router-link
								tag="a"
								to="/register"
								class="font-weight-medium text-primary"
							>{{ $t('login.signuplink') }}
							</router-link>
						</p>
						<p>
							© {{ new Date().getFullYear() }}
							<i
								class="mdi mdi-heart text-danger"
							></i>
							Romeo
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>